import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import IsBeneficialOwner from "../../../components/isBeneficialOwner"

const IsBeneficialOwnerPage = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef({})

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 3 }
  }, [])

  return (
    <AccountLayout
      title="Add beneficial owners"
      to="/account-details/professional-partnership-host/has-multiple-beneficial-owners"
      formInput={formInputRef.current}
      isDisabled={isDisabled}
    >
      <form id="form-id">
        <IsBeneficialOwner formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
      </form>
    </AccountLayout>
  )
}

export default IsBeneficialOwnerPage
